import React from 'react'; // we need this to make JSX compile

interface CardProps {
  hashCommit: string,
  ownerAndRepository: string,

}

function Card({ ownerAndRepository, hashCommit }: CardProps){
  const url = `https://github.com/${ownerAndRepository}/commit/${hashCommit}`;
  return (
    <div>
      <span>
        <a href={url} target="_blank" rel="noreferrer">{url}</a>
      </span>
    </div>
  );
}

export default Card;
