export async function getHashFirstCommit(ownerAndRepository: string, lastPageNumber: number) {
  const url = `https://api.github.com/repos/${ownerAndRepository}/commits`;
  const response = await fetch(`${url}?per_page=1&page=${lastPageNumber}`);
  const data = await response.json();
  return data[0].sha;
}

export async function getFirstCommit(ownerAndRepository: string) {
  const url = `https://api.github.com/repos/${ownerAndRepository}/commits`;
  const response = await fetch(`${url}?per_page=1`);
  const linkHeader = response.headers.get("Link");
  if (linkHeader) {
    const lastPageUrl = linkHeader.split(",")[1];
    const lastPageNumber = parseInt(lastPageUrl.split("=")[2]);
    return getHashFirstCommit(ownerAndRepository, lastPageNumber);
  } else {
    return null;
  }
}
