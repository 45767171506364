import React, {useState} from 'react';
import styles from './styles.module.css';
import { getFirstCommit } from '../../services/gitService'
import Card from '../../components/Card';

function Index() {
  const [ownerAndRepository, setownerAndRepository] = useState('');
  const [hashCommit, setHashCommit] = useState<string | null>('');

  async function onClickHandler(){
    setHashCommit(await getFirstCommit(ownerAndRepository))
  }

  return (
    <div className={styles.app}>
      <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
        <h1>First Commit</h1>
        <input placeholder="Pesquisar" onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
          setHashCommit('')
          setownerAndRepository(e.target.value)
        }
        }/>
        <small>owner/repository</small>
        <button onClick={onClickHandler} type="submit">Submit</button>
        {hashCommit && (
          <Card ownerAndRepository={ownerAndRepository}  hashCommit={hashCommit}></Card>
        )}
      </form>
    </div>
  );
}

export default Index;
